import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Button } from "../components/button";
import Layout from "../components/layout";
import Section from "../components/section";
import CTADemo from "../components/sections/cta-demo";
import SEO from "../components/seo";

const CCW2023 = () => {
    return (
        <Layout>
            <section id="hero" className="relative bg-slate-900/70 after:bg-slate-900/60 after:block after:h-full after:absolute after:right-0 after:left-0 after:top-0 after:content-['']">
                <div className="z-0 absolute left-0 bottom-0 right-0 top-0 overflow-hidden">
                    <StaticImage alt="CCW2023" src="../assets/CCW-2023-Hero.png" className="w-full h-full" imgClassName="object-cover object-bottom w-full h-full" layout="fullWidth" />
                </div>

                <div className="z-10 relative flex h-full items-center justify-center text-center px-8 py-36 sm:py-48 md:py-60 xl:px-0 container max-w-7xl mx-auto">
                    <div className="max-w-lg sm:max-w-xl md:max-w-3xl text-white">
                        <div className="space-y-2">
                            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold">
                                Meet us at CCW and learn how Coeus improves Customer Experience
                            </h1>
                        </div>
                    </div>
                </div>
            </section>

            <Section id="case-studies" className="bg-gray-50">
                <h1 className="text-center font-bold text-5xl">Case Studies</h1>
                <div className="mt-10 space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-4 content-center">
                    <div className="border p-4 bg-white">
                        <StaticImage
                            src="../assets/Case-Study-1.jpeg"
                            className="shadow-md rounded-xl overflow-hidden h-64"
                            imgClassName="rounded-xl"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            layout="fullWidth"
                            alt="Case Study #1"
                        />
                        <h2 className="text-xl font-bold py-2">Emerging Customer Expectations Drive New Technology Trends</h2>
                        <a href="#case-study-1">
                            <Button>Read More</Button>
                        </a>                        
                    </div>
                    <div className="border p-4 bg-white">
                        <StaticImage
                            src="../assets/Case-Study-2.jpeg"
                            className="shadow-md rounded-xl overflow-hidden h-64"
                            imgClassName="rounded-xl"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            layout="fullWidth"
                            alt="Case Study #2"
                        />
                        <h2 className="text-xl font-bold py-2">Improving Customer Experience by Reducing Customer Effort</h2>
                        <a href="#case-study-2">
                            <Button>Read More</Button>
                        </a>                        
                    </div>
                    <div className="border p-4 bg-white">
                        <StaticImage
                            src="../assets/Case-Study-3.jpeg"
                            className="shadow-md rounded-xl overflow-hidden h-64"
                            imgClassName="rounded-xl"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            layout="fullWidth"
                            alt="Case Study #3"
                        />
                        <h2 className="text-xl font-bold py-2">Driving Competitive Advantage by Harnessing AI</h2>
                        <a href="#case-study-3">
                            <Button>Read More</Button>
                        </a>                        
                    </div>
                    <div className="border p-4 bg-white">
                        <StaticImage
                            src="../assets/Case-Study-4.jpeg"
                            className="shadow-md rounded-xl overflow-hidden h-64"
                            imgClassName="rounded-xl"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            layout="fullWidth"
                            alt="Case Study #4"
                        />
                        <h2 className="text-xl font-bold py-2">Implementing Automation as an Agent Assist Tool</h2>
                        <a href="#case-study-4">
                            <Button>Read More</Button>
                        </a>                        
                    </div>
                    <div className="border p-4 bg-white">
                        <StaticImage
                            src="../assets/Case-Study-5.jpeg"
                            className="shadow-md rounded-xl overflow-hidden h-64"
                            imgClassName="rounded-xl"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            layout="fullWidth"
                            alt="Case Study #5"
                        />
                        <h2 className="text-xl font-bold py-2">Applying Customer Experience Design Principles</h2>
                        <a href="#case-study-5">
                            <Button>Read More</Button>
                        </a>                        
                    </div>
                    <div className="border p-4 bg-white">
                        <StaticImage
                            src="../assets/Case-Study-6.jpeg"
                            className="shadow-md rounded-xl overflow-hidden h-64"
                            imgClassName="rounded-xl"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            layout="fullWidth"
                            alt="Case Study #6"
                        />
                        <h2 className="text-xl font-bold py-2">Augmenting Agent Capacity and Reducing Agent Attrition</h2>
                        <a href="#case-study-6">
                            <Button>Read More</Button>
                        </a>                        
                    </div>
                </div>
            </Section>

            <article
                itemScope
                itemType="http://schema.org/Article"
                className="px-10 lg:px-0 py-10 space-y-6 font-openSans prose-base lg:prose-lg font-normal"
            >
                {/* Case Study #1 Article */}
                <Section id="case-study-1">
                    <h2 className="font-medium">Emerging Customer Expectations Drive New Technology Trends</h2>
                    <div className="py-4 grid lg:grid-cols-3">
                        <StaticImage
                            src="../assets/Case-Study-1.jpeg"
                            className="shadow-md rounded-xl"
                            imgClassName="rounded-xl"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            alt="Case Study #1"
                        />
                        <div className="lg:pl-8 lg:col-span-2">
                            <p>In today's society, people are expected to <strong>get more done in less time</strong> and businesses are expected to handle more customer inquiries with fewer staff. Customers and employees want to quickly find answers, solve problems, and complete transactions without having to wait for an agent to assist them.</p>
                            <p>To meet these expectations, companies need to provide <strong>24/7 access to information</strong> and adapt their customer experiences to the digital world. This includes creating hybrid experiences, like video chats between in-store employees and online customers, to mimic traditional in-person and in-store experiences.</p>
                            <p>To support these increasingly fluid and hybrid customer experiences, businesses will need to be flexible in terms of both technology and human resources. They will need to focus on effectively serving customer needs across three different interaction modes: <strong>self-service, automated, and person-to-person</strong>.</p>
                            <p>Coeus is a tool that combines a customizable, <strong>user-friendly interface</strong> with AI to provide the experiences that customers expect.</p>
                        </div>
                    </div>
                    

                    <a href="#case-studies" className="text-red-600">[Back to top]</a>
                </Section>

                {/* Case Study #2 Article */}
                <Section id="case-study-2" className="bg-gray-50">
                    <h2 className="font-medium">Improving Customer Experience by Reducing Customer Effort</h2>
                    <p>Some companies struggle to provide a consistent level of customer support and may not have the resources to respond to all customer requests and complaints. Good customer experience includes:</p>
                    <div className="grid lg:grid-cols-2 space-y-5 lg:space-y-0 lg:gap-4 content-center">
                        <div className="border p-4 bg-white">
                            <div className="flex justify-center">
                               <StaticImage
                                    src="../assets/Coeus-Icon-E.png"
                                    className="w-1/3"
                                    alt="Customer Churn"
                                /> 
                            </div>
                            <p><strong>Customer Churn:</strong> 67% of customer churn is preventable if the issue is resolved during the first interaction.</p>
                        </div>
                        <div className="border p-4 bg-white">
                            <div className="flex justify-center">
                               <StaticImage
                                    src="../assets/Coeus-Icon-G.png"
                                    className="w-1/3"
                                    alt="Customer Care"
                                /> 
                            </div>
                            <p><strong>Customer Care:</strong> 73% of customers stick with a brand where friendly employees or customer service representatives make for a memorable experience. </p>
                        </div>
                        <div className="border p-4 bg-white">
                            <div className="flex justify-center">
                               <StaticImage
                                    src="../assets/Coeus-Icon-C.png"
                                    className="w-1/3"
                                    alt="Contactless Journey"
                                /> 
                            </div>
                            <p><strong>Contactless Journey:</strong> Customers need alternatives to usual services, putting a priority on contactless solutions.</p>
                        </div>
                        <div className="border p-4 bg-white">
                            <div className="flex justify-center">
                               <StaticImage
                                    src="../assets/Coeus-Icon-F.png"
                                    className="w-1/3"
                                    alt="Easily Accessible Information"
                                /> 
                            </div>
                            
                            <p><strong>Easily Accessible Information: </strong> Pervasive access is crucial no matter the time or place.</p>
                        </div>
                    </div>
                    <p>Coeus can help with these issues by allowing customers to quickly and easily get answers to their questions or resolve their issues without the need for human interaction. It can be accessed using any device and supports voice or text communication in multiple languages. If Coeus is unable to provide an answer or solve the problem, it can seamlessly transfer the customer to a human agent.</p>
{/* TODO: Add Click Here event */}
                    <p>To learn more about Coeus, click here to talk with Zoe, Coeus’ virtual assistant.</p>
                    <a href="#case-studies" className="text-red-600">[Back to top]</a>
                </Section>
                
                {/* Case Study #3 Article */}
                <Section id="case-study-3">
                    <h2 className="font-medium">Driving Competitive Advantage by Harnessing AI</h2>
                    <div className="py-4 grid lg:grid-cols-3">
                        <div className="lg:pr-8 lg:col-span-2">
                            <p>Coeus is an artificial intelligence assistant that provides your business with intelligent answers for customers, agents, and employees. It is designed to <strong>handle routine to complex inquiries</strong> from customers and integrates with your business systems and knowledge bases to <strong>provide consistent, in-depth responses using natural language</strong>.</p>
                            <p>Coeus can adapt to your users' preferences and devices and learns over time to provide a better customer experience while reducing operational costs and increasing virtual interaction with your business. It can <strong>help agents serve customers faster, speed up the onboarding</strong> of new agents, and provide a <strong>self-service experience</strong> with up-to-date answers.</p>
                            <p>Most consumers express their frustration when they cannot resolve issues on their own because there is not enough information available online. Coeus uses artificial intelligence to quickly access more of your knowledge content and provide it to customers in an easily understood form in their own language.</p>
{/* TODO: Add Click Here event */}
                            <p>To learn more about our solution, click here to talk with Zoe, Coeus’ virtual assistant.</p>
                        </div>
                        <StaticImage
                            src="../assets/Case-Study-3.jpeg"
                            className="shadow-md rounded-xl"
                            imgClassName="rounded-xl"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            alt="Case Study #1"
                        />
                    </div>
                    
                    <a href="#case-studies" className="text-red-600">[Back to top]</a>
                </Section>

                {/* Case Study #4 Article */}
                <Section id="case-study-4" className="bg-gray-50">
                    <h2 className="font-medium">Implementing Automation as an Agent Assist Tool</h2>
                    <div className="py-4 grid lg:grid-cols-3">
                        <StaticImage
                            src="../assets/Case-Study-4.jpeg"
                            className="shadow-md rounded-xl"
                            imgClassName="rounded-xl"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            alt="Case Study #1"
                        />
                        <div className="lg:pl-8 lg:col-span-2">
                            <p>Coeus is a tool that can benefit any industry by providing <strong>assistance, consistency, and cost savings</strong> to traditional call centers. It can also help companies with a high ratio of employees to HR staff by deflecting calls. Call centers often receive a large volume of calls, which combined with high agent turnover can be costly for businesses.</p>
                            <p>Coeus can help by allowing agents to quickly access information for customers and provide consistent answers across all agents. It searches multiple sources and presents the information in a seamless response, which is easier for agents to use and faster for training new hires. By interacting with call center or customer service agents, Coeus can <strong>reduce training time for new agents, decrease call times and wait times, and provide solutions in a matter of minutes</strong>. It also helps coordinate the use of business systems and repositories.</p>
 {/* TODO: Add Click Here event */}                   
                            <p>To learn more about our solution, click here to talk with Zoe, Coeus’ virtual assistant.</p>
                        </div>
                    </div>
                    
                    <a href="#case-studies" className="text-red-600">[Back to top]</a>
                </Section>

                {/* Case Study #5 Article */}
                <Section id="case-study-5">
                    <h2 className="font-medium">Applying Customer Experience Design Principles</h2>
                    <div className="py-4 grid lg:grid-cols-3">
                        <div className="lg:pr-8 lg:col-span-2">
                            <p>As more and more people prefer to use virtual chats instead of talking on the phone, companies are finding it difficult to provide fast and efficient virtual assistants that are easy for customers to use. Younger people who are new to the workforce or going to college are familiar with technology and don't want to wait for a live agent to answer their questions. Older people often have trouble with automated phone systems that don't understand what they're saying, which leads to them having to repeat the same information multiple times. Call center agents often have to gather information from multiple sources and put it all together to figure out the answer for a customer who is getting frustrated while waiting on the line. This can lead to customers being given incorrect or inconsistent information.</p>
                            <p>Coeus has been developed using IBM Watson Assistant to solve these problems. To get the best results for your company, we start every implementation with a Design Thinking Workshop. We bring in people who work with customers directly, and if you want, we can also include some target customers. We look at the current problems and what customers want in the future based on their needs. We believe it's important to listen to what customers have to say before we set up or build Coeus, so that we can provide the best possible customer service experience for your company.</p>
                        </div>
                        <StaticImage
                            src="../assets/Case-Study-5.jpeg"
                            className="shadow-md rounded-xl"
                            imgClassName="rounded-xl"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            alt="Case Study #1"
                        />
                    </div>

                    <a href="#case-studies" className="text-red-600">[Back to top]</a>
                </Section>

                {/* Case Study #6 Article */}
                <Section id="case-study-6" className="bg-gray-50">
                    <h2 className="font-medium">Augmenting Agent Capacity and Reducing Agent Attrition</h2>
                    <div className="py-4 grid lg:grid-cols-3">
                        <StaticImage
                            src="../assets/Case-Study-6.jpeg"
                            className="shadow-md rounded-xl"
                            imgClassName="rounded-xl"
                            quality={100}
                            placeholder="blurred"
                            loading="lazy"
                            alt="Case Study #1"
                        />
                        <div className="lg:pl-8 lg:col-span-2">
                            <p>New technologies like social media for customer service, self-service portals, and customer journey mapping are changing the way that customer service works. These technologies can help companies deliver the best possible customer experience, but it's still important to have friendly staff available to provide a personal touch. It can be challenging for businesses to find the right balance of staffing for agents while also <strong>reducing turnover</strong>.</p>
                            <p>Coeus offers a feature called <strong>agent-assist technology</strong> to help with this. It gives agents access to all of the company's knowledge bases and sources through a single user interface, which means they don't have to search multiple systems to find answers for customers. This <strong>helps agents provide consistent, up-to-date responses</strong> without needing extensive training. It also makes customer interactions more enjoyable for agents because they are able to be more helpful to customers.</p>
{/* TODO: Add Click Here event */}
                            <p>To learn more about our solution, click here to talk with Zoe, Coeus’ virtual assistant.</p>
                        </div>
                    </div>
                   
                    <a href="#case-studies" className="text-red-600">[Back to top]</a>
                </Section>
            </article>

            <CTADemo />
        </Layout>
    );
};

export default CCW2023;

export const Head = () => {
    return (
        <SEO
            title="CCW 2023"
            description="Meet us at CCW and learn how Coeus improves Customer Experience"
        >
        </SEO>
    );
};
